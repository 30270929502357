var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"points-flow-table",attrs:{"resizable":true,"pageable":_vm.gridPageable,"sortable":true,"filterable":true,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"dealer_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"dealers-filter",attrs:{"data-items":_vm.dealerFilter,"text-field":'name',"data-item-key":'id',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectDealer}})]}},{key:"points_flow_type_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.typeFilter,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectType}})]}},{key:"points_flow_type",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getTypeNameByKey(dataItem.points_flow_type))+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mt-3 mb-2"},[_c('v-col',{staticClass:"pl-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('CButton',{staticClass:"export-excel-btn",attrs:{"color":"success"},on:{"click":_vm.downloadReport}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_c('span',[_vm._v("Экспорт в Excel")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }