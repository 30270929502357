var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"dealer-orders-table",attrs:{"resizable":true,"pageable":_vm.gridPageable,"sortable":true,"filterable":true,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"dealer_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.dealerFilter,"text-field":'name',"data-item-key":'id',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectDealer}})]}},{key:"area_object_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"dealers-filter",attrs:{"data-items":_vm.areaObjectFilter,"text-field":'name',"data-item-key":'id',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectAreaObject}})]}},{key:"status_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.statusFilter,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectStatus}})]}},{key:"has_additional_services_filter",fn:function(ref){
                    var methods = ref.methods;
                    var props = ref.props;
return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter(
                                'has_additional_services',
                                $event.value.value
                            )}}})]}},{key:"has_additional_services",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                    'm-badge--success':
                                        dataItem.has_additional_services,
                                    'm-badge--danger':
                                        !dataItem.has_additional_services,
                                }},[_vm._v(" "+_vm._s(dataItem.has_additional_services ? 'ДА' : 'НЕТ')+" ")])])])]}},{key:"status",fn:function(ref){
                                var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getStatusNameByKey(dataItem.status))+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mt-3 mb-2"},[_c('v-col',{staticClass:"pl-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('CButton',{staticClass:"export-excel-btn",attrs:{"color":"success"},on:{"click":_vm.downloadReport}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_c('span',[_vm._v("Экспорт в Excel")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }