export const TYPE_PRODUCT_ORDER = 0;
export const TYPE_PRODUCT_EDUCATION = 1;
export const TYPE_PRODUCT_SERVICE = 2;
export const TYPE_RETURN_ORDER = 3;
export const TYPE_RETURN_EDUCATION = 4;
export const TYPE_RETURN_SERVICE = 5;
export const TYPE_DEBITING = 6;
export const TYPE_WRITE_DOWN = 7;
export const TYPE_WRITEOFF = -1;

export default [
  { key: TYPE_PRODUCT_ORDER, name: 'Заказ по товару'},
  { key: TYPE_PRODUCT_EDUCATION, name: 'Заказ по обучению'},
  { key: TYPE_PRODUCT_SERVICE, name: 'Заказ по услуге'},
  { key: TYPE_RETURN_ORDER, name: 'Возврат по товару'},
  { key: TYPE_RETURN_EDUCATION, name: 'Возврат по обучению'},
  { key: TYPE_RETURN_SERVICE, name: 'Возврат по услуге'},
  { key: TYPE_WRITE_DOWN, name: 'Возврат по списанию'},
  { key: TYPE_DEBITING, name: 'Начисление'},
  { key: TYPE_WRITEOFF, name: 'Списание'}
]