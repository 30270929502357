<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="dealer-orders-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="cancelOrder(dataItem.id)"
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-remove"></i>
                                    Отменить
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="statusFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template v-slot:dealer_filter="{ methods, props }">
                        <dropdownlist
                            class="dealers-filter"
                            style="width: 16em"
                            :data-items="dealerFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectDealer"
                        >
                        </dropdownlist>
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{
                                getEducationOrderStatusNameByKey(
                                    dataItem.status
                                )
                            }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { showConfirm } from '@/lib/swal2'
    import { showMessage } from '@/lib/toasted'
    import educationStatuses from '@/config/education_order_statuses'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapActions, mapGetters } from 'vuex'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'
    import cancelDealerEducationOrder from '@/api/entities/orders'
    import getDealerEducationOrders from '@/api/entities/orders'
    import getEducationOrdersGeneralReport from '@/api/entities/orders'

    export default {
        name: 'DealerOrdersTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData()
            this.fetchDealerListShort()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 0,
                gridPageable: { pageSizes: true },
                sort: [{ field: 'created_at', dir: 'desc' }],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 160,
                        width: '160px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания заявки',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        type: 'text',
                        filterCell: 'dealer_filter',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'start_at',
                        title: 'Дата обучения',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'title',
                        title: 'Название курса',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'user_count',
                        title: 'Количество участников',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'format',
                        title: 'Онлайн/Офлайн',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'city',
                        title: 'Город проведения',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'price',
                        title: 'Сумма заказа',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        filterCell: 'status_filter',
                        minWidth: 160,
                        width: '160px',
                    },
                ],
                orderStatuses: educationStatuses,
            }
        },
        methods: {
            fetchData: api.orders.getDealerEducationOrders,
            ...mapActions('points_flow_items', ['fetchDealerListShort']),
            async cancelOrder(id) {
                const { isConfirmed } = await showConfirm(
                    'Вы уверены, что хотите отменить данный заказ?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.orders.cancelDealerEducationOrder(id)
                    showMessage(status, message)
                }

                await this.getData()
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key)
                } else {
                    this.removeFilter('status')
                }

                this.updateTable(true)
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id)
                } else {
                    this.removeFilter('dealer_id')
                }

                this.updateTable(true)
            },
            async downloadReport() {
                try {
                    const response =
                        await api.orders.getEducationOrdersGeneralReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Подробная статистика по заказам.xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('points_flow_items', ['dealersListShort']),
            ...mapGetters('orders', ['getEducationOrderStatusNameByKey']),
            dealerFilter() {
                return [...this.dealersListShort]
            },
            statusFilter() {
                return [...this.orderStatuses]
            },
        },
    }
</script>

<style lang="sass" scoped>
    .dealer-orders-table
      margin-top: 20px

    .actions-btn
      padding: 3px 10px !important

      &.btn::after
        content: none !important
</style>
