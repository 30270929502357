<template>
  <v-container class="general-statistics-container pr-0 pb-0 mr-0 ml-0">
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <h5>
          Период
        </h5>
      </div>
      <div>
        <v-radio-group
            v-model="reportType"
            row
        >
          <v-radio
              label="по товарам"
              value="products"
          ></v-radio>
          <v-radio
              label="по объектам площадок"
              value="area_objects"
          ></v-radio>
          <v-radio
              label="по обучению"
              value="education"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <v-menu
            ref="menu_from"
            v-model="menu_from"
            :close-on-content-click="false"
            :return-value.sync="date_from"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="shrink"
                :value="computeDate(date_from)"
                label="Дата от"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              locale="ru-RU"
              v-model="date_from"
              :max="maxDateFrom"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu_from = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="dateFromChange"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
            ref="menu_to"
            v-model="menu_to"
            :close-on-content-click="false"
            :return-value.sync="date_to"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="shrink"
                :value="computeDate(date_to)"
                label="Дата до"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              locale="ru-RU"
              v-model="date_to"
              :min="minDateTo"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu_to = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="dateToChange"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <h5>
          Дилеры
        </h5>
      </div>
      <div class="d-flex">
        <v-checkbox v-model="selectAll" @change="changeAllCheckboxes" class="dealer-checkbox" label="Выбрать всех"></v-checkbox>
      </div>
    </div>
    <div class="d-flex flex-column dealers-list">
      <div class="d-flex flex-row dealers-list__item" v-for="(dealer, key) in dealersListShort" :key="dealer.unique_id">
        <div class="d-flex flex-grow-1 dealer-name-column">
          {{ dealer.unique_id }} - {{ dealer.name }}
        </div>
        <div class="d-flex dealer-checkbox-column">
          <v-simple-checkbox :value="selectedDealers[dealer.id]" @input="changeCheckbox(dealer.id, $event)" class="dealer-checkbox"></v-simple-checkbox>
        </div>
      </div>
    </div>
    <v-row class="mt-3 mb-2">
      <v-col cols="12" class="pl-0 d-flex justify-end">
        <CButton @click="generateReport" class="export-excel-btn" color="success" :disabled="isDisabled">
          <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
        </CButton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
import api from "@/api";
import {toDataSourceRequestString} from "@progress/kendo-data-query";
import getDealerEducationOrdersProductReport from "@/api/entities/orders";

export default {
  name: "GeneralStatisticsTab",
  async mounted() {
    await this.fetchDealerListShort();
  },
  data: () => ({
    date_from: null,
    max_date_from: null,
    menu_from: false,
    date_to: null,
    min_date_to: null,
    menu_to: null,
    selectAll: false,
    reportType: null,
    selectedDealers: {}
  }),
  methods: {
    ...mapActions('dealer', ['fetchDealerListShort']),
    computeDate(date) {
      if (!date) return null

      return moment(date).format("DD.MM.YYYY")
    },
    changeAllCheckboxes(value) {
      if (value) {
        this.selectedDealers = this.allDealerIds;
      } else {
        this.selectedDealers = {};
      }
    },
    changeCheckbox(dealerId, value) {
      const selectedCheckboxes = {...this.selectedDealers};

      if (value) {
        selectedCheckboxes[dealerId] = value
      } else {
        delete selectedCheckboxes[dealerId];
      }

      this.selectedDealers = selectedCheckboxes;

      if (!value) {
        this.selectAll = false;
      }
    },
    dateFromChange() {
      this.$refs.menu_from.save(this.date_from);

      let dateFrom = moment(this.date_from);

      this.min_date_to = dateFrom.add(1, 'day').format("YYYY-MM-DD");

      if (this.date_to && dateFrom.isSameOrAfter(this.date_to)) {
        this.date_to = null;
      }
    },
    dateToChange() {
      this.$refs.menu_to.save(this.date_to);

      let dateTo = moment(this.date_to);

      this.max_date_from = dateTo.subtract(1, 'day').format("YYYY-MM-DD");

      if (this.date_from && dateTo.isSameOrBefore(this.date_from)) {
        this.date_from = null;
      }
    },
    async generateReport() {
      const method = api.orders.getDealerEducationOrdersProductReport;

      try {
        const {data} = await method({
          date_from: this.date_from,
          date_to: this.date_to,
          dealers: Object.keys(this.selectedDealers)
        });

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;

        const dateFrom = moment(this.date_from).format("DD.MM.YYYY");
        const dateTo = moment(this.date_to).format("DD.MM.YYYY");

        let fileName;

        if (this.reportType === 'products') {
          fileName = `Статистика по товарам за ${dateFrom} - ${dateTo}.xlsx`;
        } else if (this.reportType === 'education') {
          fileName = `Статистика по обучению.xlsx`
        } else {
          fileName = `Статистика по обьектам площадок за ${dateFrom} - ${dateTo}.xlsx`
        }

        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (e) {
        console.warn(e);
      }
    }
  },
  computed: {
    ...mapGetters('dealer', ['dealersListShort']),
    allDealerIds() {
      const dealerIds = {};

      this.dealersListShort.forEach(({id}) => {
        dealerIds[id] = true;
      });

      return dealerIds;
    },
    maxDateFrom() {
      return this.max_date_from ? moment(this.max_date_from).format("YYYY-MM-DD") : null;
    },
    minDateTo() {
      return this.min_date_to ? moment(this.min_date_to).format("YYYY-MM-DD") : null;
    },
    isDisabled() {
      return Object.keys(this.selectedDealers).length === 0 || !this.date_from || !this.date_to || !this.reportType
    }
  }
}
</script>

<style lang="sass" scoped>
.general-statistics-container
  max-width: 100% !important
  padding-left: 4px

.dealers-list
  &__item
    padding: 5px 0 5px 10px

.dealer-checkbox::v-deep
  margin-top: 0
  padding-top: 0

  .v-icon
    &:before
      font-size: 30px !important

  .v-label
    order: 1
    margin-right: 6px

  .v-input--selection-controls__input
    order: 2
</style>