<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="points-flow-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <!--        <template v-slot:actions="{props: {dataItem}}">-->
                    <!--          <td>-->
                    <!--            <div class="actionsColumn">-->
                    <!--              <CButton @click="cancelOrder(dataItem.id)" :disabled="!dataItem.can_cancel_order" class="actions-btn btn-square btn-info">-->
                    <!--                <i class="la la-remove"></i>-->
                    <!--                Отменить-->
                    <!--              </CButton>-->
                    <!--            </div>-->
                    <!--          </td>-->
                    <!--        </template>-->
                    <template v-slot:dealer_filter="{ methods, props }">
                        <dropdownlist
                            class="dealers-filter"
                            :data-items="dealerFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectDealer"
                        >
                        </dropdownlist>
                    </template>
                    <template
                        v-slot:points_flow_type_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="typeFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectType"
                        />
                    </template>
                    <template v-slot:points_flow_type="{ props: { dataItem } }">
                        <td>
                            {{ getTypeNameByKey(dataItem.points_flow_type) }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { mapActions, mapGetters } from 'vuex'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import pointsFlowTypes from '@/config/points_flow_types'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'PointsFlowTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            await this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                sort: [{ field: 'date', dir: 'desc' }],
                columns: [
                    {
                        field: 'dealer_unique_id',
                        title: 'КССС',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        filterCell: 'dealer_filter',
                        type: 'text',
                    },
                    {
                        field: 'income_expense',
                        title: 'Списание/Начисление баллов',
                        minWidth: 300,
                        width: '300px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'points_flow_type',
                        title: 'Тип',
                        cell: 'points_flow_type',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        filterCell: 'points_flow_type_filter',
                        type: 'text',
                    },
                    {
                        field: 'comment',
                        title: 'Примечание',
                        minWidth: 450,
                        width: '450px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'date',
                        title: 'Дата',
                        filter: 'date',
                        type: 'date',
                        minWidth: 220,
                        width: '220px',
                    },
                ],
                pointsFlowTypes,
            }
        },
        methods: {
            fetchData: api.statistics.getPointsFlowItems,
            ...mapActions('points_flow_items', ['fetchDealerListShort']),
            getTypeNameByKey(key) {
                const type = this.pointsFlowTypes.find(item => item.key === key)
                return type ? type.name : null
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id)
                } else {
                    this.removeFilter('dealer_id')
                }

                this.updateTable(true)
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            selectType({ value }) {
                if (value.key) {
                    this.setFilter('points_flow_type', 'eq', value.key)
                } else {
                    this.removeFilter('points_flow_type')
                }

                this.updateTable(true)
            },
            async downloadReport() {
                try {
                    const response =
                        await api.statistics.getDealerPointsFlowItemsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Расход бонусов(дилер).xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('points_flow_items', ['dealersListShort']),
            dealerFilter() {
                return [...this.dealersListShort]
            },
            typeFilter() {
                return [...this.pointsFlowTypes]
            },
        },
    }
</script>

<style lang="sass" scoped>
    .points-flow-table
      margin-top: 20px
</style>
